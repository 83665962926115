<script setup lang="ts">
import { InternalRouteName } from '~/constants/internal-route-name'
import type { IpxImage } from '~/types/app'

definePageMeta({
    layout: false,
    name: InternalRouteName.LOGIN,
})

const { t } = useI18n()
const currentUser = useCurrentUserState()

const authMessage = t('login_page.description_{firstName}_{lastName}', {
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
})

const { metaClient, metaServer } = usePageSeoMeta(null, { title: t('login_page.title'), noIndex: true })

const ipxImage: IpxImage = {
    relativePath: '/car-filigrane.webp',
    alt: 'car banner',
}

useSeoMeta(metaClient)
useServerSeoMeta(metaServer)
</script>

<template>
    <NuxtLayout name="default">
        <template #header>
            <VHeader
                :title="$t('login_page.title')"
                :ipx-image="ipxImage"
                layout="gradient-mask"
            />
        </template>
        <div class="main-content-width--mobile-wider box-content">
            <VLoginSection v-if="!currentUser" />
            <div v-else>
                <p>{{ authMessage }}</p>
            </div>
        </div>
    </NuxtLayout>
</template>

<style lang="scss" module>
.title {
    margin-bottom: rem(42);
}
</style>
